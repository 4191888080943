import React from 'react';
import styles from './EditPage.module.scss';
import { PublishedPage } from '../PublishedPage/PublishedPage';
import { AddLink } from "./AddLink";
import { EditList } from "./EditList";

export function EditPage() {
  return (
    <div className={styles.editPageContainer}>
      <div className={styles.editContainer}>
        <AddLink />
        <EditList />
      </div>
      <div className={styles.previewContainer}>
        <PublishedPage />
      </div>
    </div>
  )
};
