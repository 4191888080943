import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStoreContext } from '../store/Store';
import { EditableItem } from './EditableItem';

const getListStyle = isDraggingOver => ({
  width: '100%',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#F9F9D5" : "#ECFCF8",

  // styles we need to apply on draggables
  ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export function EditList() {
  const [{ listIDs }, dispatch] = useStoreContext();

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
  
      const newListIDs = reorder(
        listIDs,
        result.source.index,
        result.destination.index
      );
      dispatch({
        type: "REORDER",
        listIDs: newListIDs
      });
    },
    [listIDs, dispatch],
  )

  // state
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {listIDs.map((listId, index) => (
                <Draggable key={listId} draggableId={listId} index={index}>
                  {(provided, snapshot) => (
                    <EditableItem
                      draggableComponetProps={{
                        ref: provided.innerRef,
                        ...provided.draggableProps,
                        style: getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )
                      }}
                      dragHandleProps={provided.dragHandleProps}
                      listId={listId}
                      key={listId}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
    </DragDropContext>
  )
};
