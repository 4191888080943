import styles from './PublishedPage.module.scss';
import React from 'react';
import avatar from './cherie-du-avatar-small.jpg';
import { AiFillYoutube } from "react-icons/ai";
import { CgArrowRight } from 'react-icons/cg';
import { FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi";
import { useStoreContext } from '../store/Store';

export function PublishedPage() {
  const [state] = useStoreContext();
  
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.background}>
          <div className={styles.avatarContainer}>
            <img className={styles.avatar} alt="Avatar" src={avatar}/>
          </div>
        </div>
        <div className={styles.profile}>
          <div className={styles.name}>
            Cherie Du
          </div>
          <div className={styles.bio}>
            I am an engineering manager at Atlassian. 
          </div>
        </div>
        <div className={styles.commonSocialMedias}>
          <a href="https://www.instagram.com/cheriedu">
            <FiInstagram className={styles.commonSocialMediaIcon} />
          </a>
          <a href="https://twitter.com/Cherie_Du">
            <FiTwitter className={styles.commonSocialMediaIcon} />
          </a>
          <a href="https://www.youtube.com/channel/UC85JHyZ8UeCgu06_hdK-5Yg">
            <FiYoutube className={styles.commonSocialMediaIcon} />
          </a>
        </div>
        <div className={styles.linkList}>
          {
            state.listIDs.filter(id => state.listMap[id].isEnabled).map((itemId) => {
              const item = state.listMap[itemId];
              let urlDomain = "";
              try {
                urlDomain = new URL(item.url).hostname;
              } catch(e) {

              }
              return (
                <a key={itemId} className={styles.linkCard} href={item.url}>
                  <div className={styles.linkLogoContainer}>
                    <AiFillYoutube className={styles.logo}></AiFillYoutube>
                  </div>
                  <div className={styles.linkContent}>
                    <div>
                      {item.name}
                    </div>
                    <div className={styles.linkDomain}>
                      {urlDomain}
                    </div>
                  </div>
                  <CgArrowRight className={styles.arrowIcon}/>
                </a>
              )
            })
          }
        </div>
      </div>
    </div>
  )
};
