import produce from 'immer';

export const initialState = {
  listMap: {
    "01": {
      id: "01",
      name: "Test 1 - My awesome profil page!",
      url: "https://coolors.co/081c15-263932-44554e-808e86-f7fff7-ffe66d-52b788-a5dbc0-ff6b6b-ffa96c",
      createdDate: Date.now(),
      isEnabled: true,
    },
    "02": {
      id: "02",
      name: "Test 2 - The greatest show!",
      url: "https://www.google.com",
      createdDate: Date.now(),
      isEnabled: true,
    },
    "03": {
      id: "03",
      name: "Test 3 - The best opera!",
      url: "https://www.google.com",
      createdDate: Date.now(),
      isEnabled: true,
    }
  },
  listIDs: [
    "01",
    "02",
    "03",
  ]
};

export function listReducer(state, action) {
  switch (action.type) {
    case 'ADD_LINK': {
      const newLink = {
        id: `0${state.listIDs.length + 1}`,
        name: "",
        url: "",
        createdDate: Date.now(),
        isEnabled: false,
      };
      return produce(state, draft => {
        draft.listMap[newLink.id] = newLink;
        draft.listIDs.splice(0, 0, newLink.id);
      });
    }
    case 'REMOVE_LINK': {
      const idx = state.listIDs.indexOf(action.id);
      if (idx < 0) throw new Error(`ID: ${action.id} not found.`);
      
      return produce(state, draft => {
        draft.listIDs.splice(idx, 1);
        delete draft.listMap[action.id];
      });
    }
    case "REORDER": {
      return produce(state, draft => {
        draft.listIDs = action.listIDs;
      });
    }
    case "UPDATE_NAME": {
      return produce(state, draft => {
        draft.listMap[action.id].name = action.name;
      });
    }
    case "UPDATE_URL": {
      return produce(state, draft => {
        draft.listMap[action.id].url = action.url;
      });
    }
    case "TOGGLE_ENABLED": {
      return produce(state, draft => {
        draft.listMap[action.id].isEnabled = action.isEnabled;
      });
    }
    default:
      throw new Error();
  }
}
