import React from 'react';

const StoreContext = React.createContext();
StoreContext.displayName = 'Store';

export const useStoreContext = () => React.useContext(StoreContext);

export const StoreProvider = ({ initialState, dispatch, children }) => {
  return <StoreContext.Provider value={[initialState, dispatch]}>
    {children}
  </StoreContext.Provider>
}