import styles from './AddLink.module.scss';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useStoreContext } from '../store/Store';


export function AddLink() {
  const [, dispatch] = useStoreContext();
  const memoizedCallback = useCallback(
    () => {
      dispatch({
        type: "ADD_LINK",
        name: `myDummyName ${Math.floor(Math.random() * 100)}`,
        url: "https://www.youtube.com/"
      })
    },
    [dispatch],
  );

  return (
    <div className={styles.addLinkContainer}>
      <Button 
        variant="outline-primary"
        size="sm"
        className={styles.addLinkButton}
        onClick={memoizedCallback}
      >
        Add A New Link!
      </Button>
    </div>
  )
};
