import styles from './App.module.scss';
import React, { useState, useCallback, useReducer } from 'react';
import GoogleLogin from 'react-google-login';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { PublishedPage } from "./PublishedPage/PublishedPage";
import { EditPage } from "./EditPage/EditPage";
import { initialState, listReducer } from "./store/reducer";
import { StoreProvider } from "./store/Store";

const responseGoogle = (response) => {
  console.log(response);
}

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [state, dispatch] = useReducer(listReducer, initialState);
  const onSuccess = useCallback(
    async (googleData) => {
    //   const res = await fetch("/api/v1/auth/google", {
    //     method: "POST",
    //     body: JSON.stringify({
    //     token: googleData.tokenId
    //   }),
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // });
    // const data = await res.json()
      console.log("sign in success");
      console.log("data", googleData);
      setIsSignedIn(true);
    },
    [setIsSignedIn],
  )
  return (
    <StoreProvider
      initialState={state}
      dispatch={dispatch}
    >
      <Router>
        <div className={styles.app}>
          <Switch>
            <Route exact path="/edit">
              <EditPage />
            </Route>
            <Route exact path="/:userId">
              <PublishedPage />
            </Route>
            <Route path="/login">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Sign up with Google"
                onSuccess={onSuccess}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                isSignedIn={isSignedIn}
              />
            </Route>
            <Route exact path="/">
              <PublishedPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;
