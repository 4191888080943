import "react-toggle/style.css"
import React from 'react';
import { GrDrag } from "react-icons/gr";
import { CgTrash } from "react-icons/cg";

import { InlineEditableTextfield } from '@atlaskit/inline-edit';

import Toggle from 'react-toggle'

import { useStoreContext } from '../store/Store';
import styles from "./EditableItem.module.scss";

export const EditableItem = ({ draggableComponetProps, dragHandleProps, listId }) => {
  const [{ listMap }, dispatch] = useStoreContext();
  const item = listMap[listId];
  return (
    <div className={styles.editableItemContainer} {...draggableComponetProps}>
      <div className={styles.draggableIconContainer} {...dragHandleProps}>
        <GrDrag className={styles.draggableIcon}></GrDrag>
      </div>
      <div className={styles.linkInformationContainer}>
        <InlineEditableTextfield
          className={styles.linkName}
          defaultValue={item.name}
          onConfirm={(value) => dispatch({ type:"UPDATE_NAME", name: value, id: listId })}
          placeholder="Click to enter text"
          hideActionButtons
          isCompact
          readViewFitContainerWidth
        />
        <InlineEditableTextfield
          className={styles.linkDomain}
          defaultValue={item.url}
          onConfirm={(value) => dispatch({ type:"UPDATE_URL", url: value, id: listId })}
          placeholder="Click to enter text"
          hideActionButtons
          isCompact
          readViewFitContainerWidth
        />
      </div>
      <div className={styles.toggleDisplay}>
        <Toggle
          className={styles.toggleButton}
          defaultChecked={item.isEnabled}
          icons={false}
          onChange={(e) => {
            dispatch({ type: "TOGGLE_ENABLED", isEnabled: e.target.checked, id: listId });
          }}
        />
        <button className={styles.trashButton} onClick={() => dispatch({ type: "REMOVE_LINK", id: listId})}>
          <CgTrash size="24"></CgTrash>
        </button>
      </div>
    </div>
  )
}